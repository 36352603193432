<template>
      <div class="tooltip">
    <span class="tooltip-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">?</span>
    <div v-if="showTooltip" class="tooltip-content">
      <table>
        <tr v-for="(line, index) in 5" :key="index">
          <td>Ligne {{ index + 1 }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
    name:'TooltipComponent',
  data() {
    return {
      showTooltip: false
    };
  }
};
</script>
<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #555;
  color: #fff;
  cursor: pointer;
}

.tooltip .tooltip-content {
  visibility: hidden;
  position: absolute;
  background-color: #555;
  color: #fff;
  text-align: center;
  /* border-radius: 6px; */
  padding: 5px 0;
  z-index: 1;
  width: 10rem;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}

.tooltip-content table {
  width: 100%;
}

.tooltip-content td {
  padding: 5px;
}
</style>