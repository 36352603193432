<template>
  <div class="fr-px-4w fr-pt-3w">
    <pie-chart :x="JSON.stringify(serieObj.serie_values.x)" :y="JSON.stringify(serieObj.serie_values.y)"
      :name="JSON.stringify(serieObj.serie_values.legend_names)" :date="serieObj.update_date" :fill="true" :color=JSON.stringify(serieObj.color)>
    </pie-chart>
  </div>
</template>

<script>
import { onMounted } from "vue";
import PieChart from "./components_dsfr/PieChart.vue";
export default {
  components: {
    PieChart,
  },
  name: "PieBox",
  props: {
    serieObj: Object,
  }
};


</script>
