<template>
  <div>
    <div id="app">
      <app-skiplinks></app-skiplinks>
      <app-header></app-header>
      <main id="content" role="main">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import headerDsfr from "./components/Header.vue";
import footerDsfr from "./components/Footer.vue";
import SkipLinksDsfr from "./components/SkipLinks.vue";

export default {
  components: {
    "app-header": headerDsfr,
    "app-footer": footerDsfr,
    "app-skiplinks": SkipLinksDsfr,
  },
  watch: {
    $route(to, from) {
      document.title =
        to.meta.title ||
        "L’Observatoire des données des sites Internet de l’État";
    },
  },
};
</script>
<style>
body {
  background-color: white;
  /*rgb(246,246,246);*/
}
</style>
