<template>
  <fieldset class="fr-segmented fr-segmented--no-legend fr-segmented--sm">
    <legend>
      Choix de visualisation du graphique : graphique ou table
    </legend>
    <div class="fr-segmented__elements" aria-label="segment-choice" role="group">
      <div class="fr-segmented__element">
        <input
          value="1"
          type="radio"
          :id="idcontrol + '-1'"
          :name="idcontrol + 'segmented-2230'"
          @click="emitChartSelected('graphique')"
          checked
          data-fr-analytics-click
          data-fr-analytics-type="interaction"
        />
        <label class="fr-label" :for="idcontrol + '-1'" title="Graphique">
          Graphique
        </label>
      </div>
      <div class="fr-segmented__element">
        <input
          value="2"
          type="radio"
          :id="idcontrol + '-2'"
          :name="idcontrol + 'segmented-2230'"
          @click="emitChartSelected('table')"
          data-fr-analytics-click
          data-fr-analytics-type="interaction"
        />
        <label class="fr-label" :for="idcontrol + '-2'" title="Table">
          Table
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  data() {
    return {
      defaultChart: "graphique", // Set the default chart type here
    };
  },
  props: {
    idcontrol: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Emit the default chart type when the component is mounted
    this.emitChartSelected(this.defaultChart);
  },
  methods: {
    emitChartSelected(type) {
      this.$emit("chart-selected", type);
    },
  },
};
</script>

<style>
.fr-segmented__elements {
  align-items: right;
}
</style>


<label></label>
<input>
</input>
