<template>
  <div class="fr-card fr-card--no-icon fr-card--shadow fr-mt-5w">
    <div class="fr-p-2w">
      <h3 class="cardTitle" :aria-labelledby="dataObj.accordion.id + '1'">{{ dataObj.titleBox.title }}</h3>
      <p class="fr-text--sm fr-text--regular fr-mb-1w"> {{ dataObj.accordion.description }} </p>
    </div>

    <div class="fr-grid-row--right fr-px-1w cardReference">
      <segmented-controls v-on:chart-selected="handleChartSelected"
        :idcontrol="dataObj.accordion.id + '1'"></segmented-controls>
    </div>

    <div :is="dataObj.graph.component" v-bind="{ serieObj: dataObj.graph.props }" v-if="displayChart"></div>
    <table-component v-else :captionTitle="dataObj.titleBox.title" :table="dataObj.graph.table"></table-component>
  </div>
</template>

<script>
import SegmentedControls from "./SegmentedControls.vue";
import TableComponent from "./TableComponent.vue";

export default {
  name: "GraphBoxGeneric",
  components: {
    SegmentedControls,
    TableComponent,
  },
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayChart: false,
    };
  },
  methods: {
    handleChartSelected(type) {
      this.displayChart = type === "graphique";
    },
  },
};
</script>

<style scoped>
.cardReference {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  display: flex;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
</style>
