<template>    
  <div class="fr-pt-3w">
    <div class="fr-px-1w fr-pt-3w">
      <div class="fr-grid-row">
        <div class="fr-col-12 fr-col-9">
            <bar-chart
              :x=JSON.stringify(serieObj.serie_values.x)
              :y=JSON.stringify(serieObj.serie_values.y)
              :name=JSON.stringify(serieObj.serie_values.name)
              :date=serieObj.update_date
              :horizontal=true
              >
            </bar-chart>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>

import BarChart from './components_dsfr/BarChart.vue'

export default {
name: 'BarChartGrid',
components: {
  BarChart
},
props:{
  serieObj: Object
},
mounted(){
}
}
</script>
