<template>
  <div>
    <div v-if="!queryFinishSerie" class="fr-mt-1w" aria-live="polite">
      <div class="loading">
        <div class="loading__spinner"></div>
      </div>
    </div>

    <div class="fr-pt-3w fr-pb-5w">
      <section>
        <!-- Frequentation -->
        <h1 id="frequentation" class="fr-mb-4w">
          Fréquentation des sites Internet de l’État
        </h1>
        <div id="explanation">
          <p>
            Interagissez avec les données d'audience des <b> {{ nb_sites_visits }} sites</b> sites de l'État qui les ont publiées.
            Les statistiques sont détaillée et mises à jour régulièrement tous les jours, offrant une vision transparente et complète des performances de l'écosystème numérique public.
          </p>
        </div>
      </section>

      <section>
        <!-- TOP Sites -->
        <GraphBoxGeneric :dataObj="topSitesObj" v-if="queryTopSitesSuccess"></GraphBoxGeneric>
      </section>

      <section>
        <!-- Mobile average chart -->
        <GraphBoxGeneric :dataObj="visiteObj" v-if="querySuccess"></GraphBoxGeneric>
        <GraphBoxGeneric :dataObj="vueObj" v-if="querySuccess"></GraphBoxGeneric>
        <GraphBoxGeneric :dataObj="durationObj" v-if="querySuccess"></GraphBoxGeneric>
      </section>

      <section>
        <!-- Accès aux sites Pie-charts -->
        <h2 id="access-site" class="fr-mt-5w fr-mb-n1w" v-if="queryPiechartSuccess">
          Accès aux sites
        </h2>

        <GraphBoxGeneric class="fr-mb-1w" :dataObj="deviceObj" v-if="queryPiechartSuccess"></GraphBoxGeneric>
        <GraphBoxGeneric class="fr-mb-1w" :dataObj="sourceObj" v-if="querySourcesSuccess"></GraphBoxGeneric>
      </section>

      <section>
        <!-- Type de site -->
        <h2 id="type-site" class="fr-mt-5w fr-mb-n1w" v-if="queryBarChartSuccess">
          Type de site
        </h2>

        <GraphBoxGeneric :dataObj="ministereObj" v-if="queryBarChartSuccess"></GraphBoxGeneric>
        <GraphBoxGeneric :dataObj="typeSiteObj" v-if="queryTypeSitesSuccess"></GraphBoxGeneric>
      </section>

      <section v-if="querySuccess">
        <!-- Cartographie -->
        <h2 id="provenance-geographique" class="fr-mt-5w fr-mb-n1w">
          Provenance géographique
        </h2>
        <MapsBox :query="query"></MapsBox>
      </section>

      <section>
        <div class="fr-card fr-card--no-icon fr-p-3w" v-if="!querySuccess">
          <h3>Aucune donnée ne correspond à cette requête sur cette période</h3>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LineSeriesChart from "./LineSeriesChart.vue";
import PieBox from "./PieBox.vue";
import BarSeriesChart from "./BarSeriesChart.vue";
import GraphBoxGeneric from "./GraphBoxGeneric.vue";
import MapsBox from "./MapsBox.vue";

export default {
  name: "ChartVisits",
  components: {
    LineSeriesChart,
    PieBox,
    BarSeriesChart,
    GraphBoxGeneric,
    MapsBox
  },
  data() {
    return {
      //series
      querySuccess: false,
      visiteObj: Object,
      vueObj: Object,
      durationObj: Object,
      //pie chart
      queryPiechartSuccess: false,
      querySourcesSuccess: false,
      deviceObj: Object,
      sourceObj: Object,
      //bar chart
      queryBarChartSuccess: false,
      ministereObj: Object,      
      queryTypeSitesSuccess: false,
      typeSiteObj: Object,
      nb_sites_visits: 0,
      queryFinishSerie: false,
      //top sites
      topSitesObj: Object,
      queryTopSitesSuccess: false
      
    };
  },
  props: {
    query: Object,
  },
  methods: {
    graphics(params) {
      const urlApi = process.env.VUE_APP_API_URL + "/requests/query_visite";
      axios.post(urlApi, params).then((response) => {
        if (response.data.status) {
          this.querySuccess = true;
          var data = response.data.data;

          this.visiteObj = {
            titleBox: {
              title: data.series[0].nameX// + data.name
            },
            graph: {
              component: LineSeriesChart,
              props: data.series[0],
              table: data.series[0].table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.series[0].description,
              id: "myaccordion1",
              open: false
            },
          };

          this.vueObj = {
            titleBox: {
              title: data.series[1].nameX
            },
            graph: {
              component: LineSeriesChart,
              props: data.series[1],
              table: data.series[1].table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.series[1].description,
              id: "myaccordion2",
              open: false
            }
          };

          this.durationObj = {
            titleBox: {
              title: data.series[2].nameX
            },
            graph: {
              component: LineSeriesChart,
              props: data.series[2],
              table: data.series[2].table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.series[2].description,
              id: "myaccordion3",
              open: false
            }
          };
        } else {
          this.querySuccess = false;
        }
        this.queryFinishSerie = true
      });
    },
    graphics_barChart(params) {
      const urlApi = process.env.VUE_APP_API_URL + "/requests/visits_ministere";
      axios.post(urlApi, params).then((response) => {
        if (response.data.status) {
          this.queryBarChartSuccess = true;
          var data = response.data.data.series[0];

          this.ministereObj = {
            titleBox: {
              title: data.title
            },
            graph: {
              component: BarSeriesChart,
              props: data,
              table: data.table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.description,
              id: "myaccordion6",
              open: false
            }
          };
        } else {
          this.queryBarChartSuccess = false;
        }
      });
    },
    pie_chart_data(params) {
      const urlApi = process.env.VUE_APP_API_URL + "/requests/visits_device";
      axios.post(urlApi, params).then((response) => {
        if (response.data.status) {
          this.queryPiechartSuccess = true;
          var data = response.data.data.series[0];
          data.color = ['green-bourgeon', 'blue-ecume', 'purple-glycine-main-494', 'yellow-moutarde-main-679']

          this.deviceObj = {
            titleBox: {
              title: data.title
            },
            graph: {
              component: PieBox,
              props: data,
              table: data.table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.description,
              id: "myaccordion4",
              open: false
            }
          };
        } else {
          this.queryPiechartSuccess = false;
        }
      });
    },
    pie_chart_sources(params) {
      const urlApi = process.env.VUE_APP_API_URL + "/requests/visits_source";

      axios.post(urlApi, params).then((response) => {
        if (response.data.status) {
          this.querySourcesSuccess = true;
          var data = response.data.data.series[0];
          data.color = ['blue-ecume', 'green-archipel-925', 'brown-opera-moon-820', 'purple-glycine-main-494', 'yellow-moutarde-main-679', 'purple-glycine', 'green-bourgeon', 'warning-main-525', 'grey-850']

          this.sourceObj = {
            titleBox: {
              title: data.title
            },
            graph: {
              component: PieBox,
              props: data,
              table: data.table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.description,
              id: "myaccordion5",
              open: false
            },
          };
        } else {
          this.querySourcesSuccess = false;
        }
      });
    },
    graphics_barChart_typesite(params) {
      const urlApi = process.env.VUE_APP_API_URL + "/requests/visits_typesite";
      axios.post(urlApi, params).then((response) => {
        if (response.data.status) {
          this.queryTypeSitesSuccess = true;
          var data = response.data.data;
          this.typeSiteObj = {
            titleBox: {
              title: data.series[0].title
            },
            graph: {
              component: BarSeriesChart,
              props: data.series[0],
              table: data.series[0].table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.series[0].description,
              id: "myaccordion",
              open: false
            }
          };
        } else {
          this.queryTypeSitesSuccess = false;
        }
      });
    },
    get_nb_sites(kind) {
      const urlApi =
        process.env.VUE_APP_API_URL + "/requests/nb_sites?kind=" + kind;
      axios.get(urlApi).then((response) => {
        if (response.data.status) {
          this.nb_sites_visits = response.data.data.nb_sites;
        }
      });
    },
    graphics_top_sites(params) {
      const urlApi = process.env.VUE_APP_API_URL + "/requests/top_sites";
      params.limit = 10      
      axios.post(urlApi, params).then((response) => {
        if (response.data.status) {
          this.queryTopSitesSuccess = true;
          var data = response.data.data.series[0];

          this.topSitesObj = {
            titleBox: {
              title: data.title
            },
            graph: {
              component: BarSeriesChart,
              props: data,
              table: data.table
            },
            accordion: {
              title: "En savoir plus sur l'indicateur",
              description: data.description,
              id: "myaccordion7",
              open: false
            }
          };
        } else {
          this.queryTopSitesSuccess = false;
        }
      });
    },
    all_graphics() {
      // this.maps(this.query)
      this.get_nb_sites("display_visits_page");
      this.graphics(this.query);
      this.pie_chart_data(this.query);
      this.graphics_barChart(this.query);
      this.graphics_barChart_typesite(this.query);
      this.pie_chart_sources(this.query);
      this.graphics_top_sites(this.query)
    }
  },
  watch: {
    query: function () {
      this.all_graphics();
    }
  },
  mounted() {
    this.all_graphics();
  }
};
</script>
<style scoped lang="scss">
#explanation {
  font-size: small;
}

.link-rgpd {
  color: blue;
}

.loading {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0.3px;
  z-index: 9999;

  &__spinner {
    margin-bottom: 250px;
    width: 100px;
    height: 100px;
    border: 5px solid #1b1b35;
    background-color: transparent;
    border-top-color: transparent;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 0.75s;
    visibility: 0.75s;
    animation: fr-loading-spinner linear infinite 1500ms;
  }
}

@keyframes fr-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
