<template>
  <section class="dropdown-wrapper">
    <div @click="
      isVisible = !isVisible;
    emitOpen(isVisible);
    " class="selected-item fr-input">
      <span v-if="selectedItem" data-fr-analytics-click="selected-sites">{{
      selectedItem["label"]
    }}</span>
      <span v-else id="all-sites-label" data-v-853640ee="" data-fr-analytics-click="all-sites"
        data-fr-js-attribute-actionee="true">Tous les sites</span>
      <svg class="drop-down-icon" :class="isVisible ? 'dropdown' : ''" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
        <path
          d="M11.9999 10.8284L7.0502 15.7782L5.63599 14.364L11.9999 8L18.3639 14.364L16.9497 15.7782L11.9999 10.8284Z">
        </path>
      </svg>
    </div>
    <div :class="isVisible ? 'visible' : 'invisible'" class="dropdown-popover fr-select-group" role="compobox"
      aria-expanded="false">
      <input class="fr-input" type="text" v-model="searchQuery" placeholder="Rechercher" />
      <span v-if="filteredItem.length === 0">Pas de résultats</span>

      <div class="options">
        <ul>
          <li id="chosen-site-label" data-v-853640ee="" data-fr-analytics-click="chosen-site"
            data-fr-js-attribute-actionee="true" v-for="item in filteredItem" :key="item['value']"
            @click="selectItem(item)">
            {{ item["label"] }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      isVisible: false,
      SitesListe: [],
    };
  },
  props: {
    itemList: {
      type: Array,
      default: () => [],
      required: true,
    },
    resetFilter: {
      required: false,
    },
  },
  computed: {
    filteredItem() {
      const query = this.searchQuery.toLowerCase();

      if (this.searchQuery === "") {
        return this.SitesListe;
      }

      return this.SitesListe.filter((item) => {
        return Object.values(item).some((word) =>
          String(word).toLowerCase().includes(query)
        );
      });
    },
  },
  watch: {
    itemList: {
      handler() {
        this.searchQuery = "";
        this.selectedItem = undefined;
        this.SitesListe = this.itemList;
      },
      deep: true,
    },
    resetFilter: {
      handler() {
        if (this.resetFilter === undefined) {
          this.searchQuery = "";
          this.selectedItem = undefined;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.SitesListe = this.itemList;
  },
  methods: {
    emitOpen(visible) {
      this.$emit("open", visible);
    },

    selectItem(item) {
      this.selectedItem = item;
      this.searchQuery = "";
      this.isVisible = false;
      this.$emit("open", this.isVisible);
      this.$emit("item-selected", item);
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-wrapper {
  position: relative;
  margin: 0 auto;

  .selected-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .drop-down-icon {
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
    }
  }

  .dropdown-popover {
    position: absolute !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    top: 40px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 999999 !important;
    max-width: 100%;
    padding: 10px;
    visibility: hidden;
    transition: all 0.3s linear;
    max-height: 0px;
    overflow: hidden !important;

    &.visible {
      visibility: visible;
      max-height: 500px;
    }

    // input {
    //     width: 100%;
    //     height: 32px;
    //     border: 1px solid #ccc;
    //     padding: 0 10px;
    // }

    .options {
      width: 100%;

      ul {
        list-style: none;
        text-align: left;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 0rem !important;

        li {
          padding: 5px 5px !important;
          cursor: pointer;
          width: 100%;

          &:hover {
            background: #1967d2;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
